.overlay {
  margin: 15px;
  cursor: pointer;
  .line-1 {
    width: 30px;
    height: 4px;
    border-radius: 5px;
    background-color: #222222;
  }
  .line-mid {
    width: 30px;
    height: 4px;
    margin: 5px 0;
    border-radius: 5px;
    background-color: #222222;
  }
  .line-3 {
    width: 30px;
    height: 4px;
    margin: 5px 0;
    border-radius: 5px;
    background-color: #222222;
  }
}
