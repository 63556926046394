.footer {
  margin-top: 18px;
  @media (min-width: 768px){
    font-size: 20px;
    position: absolute;
    bottom: 25px;
  }
  .need-help-title {
    font-size: 28px;
    letter-spacing: 0;
    font-weight: bold;
  }
  .contact {
    color: #222222;
    display: flex;
    font-size: 18px;
    margin-top: 10px;
    @media(min-width: 768px) {
      font-size: 20px
    }
    .phone {
      color: #222222;
      margin-left: 5px;
    }
  }
}