.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: absolute;
  background-color: white;
  min-height:100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  color: #222222;
  @media(min-width: 768px){
    justify-content: center;
  }
  .modal-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-width: 966px;
    height: 100%;
    @media(min-width: 768px) {
      height: 95%;
      box-shadow: 0px 3px 6px #00000029;
      padding: 15px;
      border-radius: 10px;
      width: 100%;
    }
    .close {
      height: 20px;
      width: 20px;
      margin: 15px;
      cursor: pointer;
    }
    .close-container {
      display: none;
      @media(min-width: 768px) {
        justify-content: flex-end;
        display: flex;
      }
    }
    .modal-header {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      height: 60px;
      .back-container {
        height: 100%;
        display: flex;
        align-items: center;
        @media(min-width: 768px) {
          display: none;
        }
        .back {
          position: absolute;
          left: 5%;
          border: solid #222222;
          border-width: 0 4px 4px 0;
          display: inline-block;
          transform: rotate(135deg);
          height: 18px;
          width: 18px;
          cursor: pointer;
        }
      }
      .title {
        font-size: 28px;
        font-weight: bold;
        @media(min-width: 768px) {
          font-size: 30px;
        }
      }
    }
    .error {
      color: red;
      text-align: center;
    }
    .inputs-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5px;
      height: 90%;
      overflow-y: scroll;
      @media(min-width: 768px) {
        margin-top: 15px;
      }
      .input-container {
        margin-top: 15px;
        width: 100%;
        max-width: 600px;
        .label {
          font-size: 16px;
          letter-spacing: 0;
          color: rgb(138, 138, 138);
        }
        .input {
          width: 100%;
          height: 55px;
          background: #FBFBFB 0% 0% no-repeat padding-box;
          border: 1px solid #E2E2E2;
          border-radius: 5px;
          padding-left: 10px;
          margin-top: 5px;
          font-size: 20px;
          color: #222222;
        }
      }
      .divided-inputs {
        width: 100%;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        max-width: 600px;
        .input-container-divided {
          width: 48%;
          .label {
            font-size: 16px;
            letter-spacing: 0;
            color: rgb(138, 138, 138);
          }
          .input {
            width: 100%;
            height: 55px;
            background: #FBFBFB 0% 0% no-repeat padding-box;
            border: 1px solid #E2E2E2;
            border-radius: 5px;
            padding-left: 10px;
            margin-top: 5px;
            font-size: 20px;
            color: #222222;
          }
          select {
            width: 100%;
            height: 55px;
            border: 1px solid #E2E2E2;
            outline: none;
            font-size: 20px;
            padding-left: 10px;
            border-radius: 5px;
            background: #FBFBFB 0% 0% no-repeat padding-box;
            margin-top: 5px;
            -webkit-border-radius: 5px;
            -webkit-appearance:none;
          }
          .option option{
            width: 100%;
            height: 30px;
          }
        }
      }
    }
    .update-info-button-container {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 10%;
      @media(min-width: 768px) {
        justify-content: flex-end;
      }
      .update-info-button {
        width: 335px;
        height: 50px;
        background: #3F3250 0% 0% no-repeat padding-box;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 22px;
        cursor: pointer;
        @media(min-width: 768px) {
          width: 190px;
          height: 55px;
          border-radius: 5px;
          font-size: 21px;
        }
      }
    }
    .actions-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
      @media(min-width: 768px) {
        margin-bottom: 0px;
      }
      .card-button-delete, .card-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 55px;
        background: #3F3250 0% 0% no-repeat padding-box;
        border-radius: 5px;
        color: white;
        font-size: 20px;
        cursor: pointer;
      }
      .card-button-delete {
        background: #A32C3E 0% 0% no-repeat padding-box;
      }
    }
    .set-default-card {
      display: flex;
      width: 100%;
      margin-top: 15px;
      max-width: 600px;
      .box-container {
        width: 23px;
        height: 23px;
        background-color: #FFFFFF;
        border: 2px solid #3F3250;
        display: flex;
        justify-content: center;
        align-items: center;
        .box {
          width: 15px;
          height: 15px;
          border: none;
        }
      }
      .default-title {
        font-size: 19px;
        margin-left: 12px;
      }
    }
  }
}