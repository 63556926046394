.card-container:not(:first-of-type) {
  margin-top: 10px;
  @media(min-width: 768px) {
    margin-top: 10px;
  }
}

.card-container {
  background-color: #FEFEFE;
  width: 100%;
  border: 2px solid #3F3250;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  color: #22252C;
  font-size: 16px;
  @media(min-width: 768px) {
    height: 110px;
    width: 80%;
    font-size: 20px;
  }
  .left-card, .right-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .default {
      margin-bottom: 10px;
      font-size: 10px;
      @media(min-width: 768px) {
          margin-bottom: 16px;
          font-size: 14px;
      }
    }
  }
  .right-card {
    align-items: flex-end;
    .edit{
      cursor: pointer;
      width: fit-content;
      padding-right: 8px;
    }
    .expire_date  {
      width: fit-content;
      padding-right: 8px;
    }
  }
}

