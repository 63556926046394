.login-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
  width: 100%;
  position: absolute;
  z-index: 2;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  font-family: 'Helvetica Neue', sans-serif;
  .login-page-content {
    width: 100%;
    max-width: 380px;
    .logo-container {
      @media (min-width: 768px) {
        text-align: center;
        display: flex;
        justify-content: center;
      }
    }
    .title {
      font-size: 28px;
      color: #1C1C1C;
      font-weight: bold;
      margin-top: 60px;
      @media (min-width: 768px) {
        text-align: center;
        font-size: 32px;
        margin-top: 50px;
      }
    }
    .form-container {
      margin-top: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .login-error {
        color: red;
        width: 100%;
        max-width: 380px;
        margin-top: 20px;
      }
      .email-container {
        border-bottom: 1px solid #1C1C1C;
        padding-bottom: 10px;
        max-width: 500px;
        width: 100%;
        .email-input, .email-input::placeholder {
          font-size: 20px;
          padding: 0px;
          border: 0px;
          color: rgba(28, 28, 28, 0.780);
          width: 100%;
        }
      }
      .password-container {
        border-bottom: 1px solid #1C1C1C;
        padding-bottom: 10px;
        margin-top: 40px;
        max-width: 500px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .password-eye-container{
          height: 14px;
          cursor: pointer;
        }
        .password-input, .password-input::placeholder {
          font-size: 20px;
          padding: 0px;
          border: 0px;
          color: rgba(28, 28, 28, 0.780);
          width: 100%;
        }
      }
      .forgot-password {
        font-size: 16px;
        color: #707070;
        font-style: italic;
        margin-top: 20px;
        width: 100%;
      }
      .login-button-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 30px;
        @media (min-width: 768px) {
          margin-top: 50px;
        }
        .login-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #3F3250 0% 0% no-repeat padding-box;
          border-radius: 30px;
          width: 160px;
          height: 60px;
          font-size: 24px;
          color: white;
          cursor: pointer;
          @media (min-width: 768px) {
            width: 268px;
          }
        }
      }
    }
  }
}