.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  margin-top: 30px;
  width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  margin-top: 8px;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripe-container {
  z-index: 1;
  margin-top: 15px;
  width: 100%;
  .label {
    font-size: 16px;
    letter-spacing: 0;
    color: rgb(138, 138, 138);
  }
}