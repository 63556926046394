


.change-password-modal-header {
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 60px;
  .change-password-title {
    font-size: 28px;
    font-weight: bold;
    @media(min-width: 768px) {
      font-size: 30px;
    }
  }
  .back-container {
    height: 100%;
    display: flex;
    align-items: center;
    @media(min-width: 768px) {
      display: none;
    }
    .back {
      position: absolute;
      left: 5%;
      border: solid #222222;
      border-width: 0 4px 4px 0;
      display: inline-block;
      transform: rotate(135deg);
      height: 18px;
      width: 18px;
      cursor: pointer;
      @media(min-width: 768px) {
        position: relative;
        display: block;
        left: 0%;
      }
    }
  }
}

.inputs-container {
  @media(min-width: 768px) {
    margin-top: 15px;
  }
}