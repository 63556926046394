.billing-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: 768px) {
    align-items: flex-start;
  }
  .content-container {
    width: 90%;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    @media(min-width: 768px) {
      width: 100%;
      padding-left: 80px;
    }
    .billing-title {
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 0;
      color: #222222;
      margin-top: 30px;
      @media(min-width: 768px) {
        font-size: 36px;
        margin-top: 70px;
      }
    }
    .billing-details-container {
      margin-top: 20px;
      background: #FBFBFB 0% 0% no-repeat padding-box;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 0px;
      @media(min-width: 768px) {
        font-size: 20px;
        margin-right: 30px;
      }
      .title {
        font-size: 22px;
        color: #707070;
        width: 90%;
        @media(min-width: 768px) {
          font-size: 28px;
        }
      }
      .cards-container {
        max-height: 220px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-top: 15px;
        @media(min-width: 768px) {
          max-height: 300px;
        }
      }
      .update-billing-button-container {
        width: 90%;
        margin-top: 20px;
        .update-billing-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 150px;
          height: 40px;
          background: #3F3250 0% 0% no-repeat padding-box;
          border-radius: 20px;
          font-size: 18px;
          color: #FFFFFF;
          cursor: pointer;
          @media(min-width: 768px) {
            width: 240px;
            height: 55px;
            font-size: 24px;
            border-radius: 30px;
          }
        }
      }
    }
  }
}