.subscription-card-container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  @media(min-width: 768px) {
    width: 49%;
    height: 225px;
  }
  .subscription-svg-24 {
    position: absolute;
    color: black;
    background-color: transparent;
    opacity: 0.6;
    right: 12px;
    width: 50px;
  }
  .subscription-svg-12 {
    position: absolute;
    color: black;
    background-color: transparent;
    opacity: 0.6;
    right: 14px;
    width: 40px;
  }
  .subscription-card {
    width: 90vw;
    height: 100%;
    position: relative;
    transition: transform 0.4s ease-in-out;
    transform-style: preserve-3d;
    max-width: 650px;
    .subscription-container {
      height: fit-content;
      width: 100%;
      backface-visibility: hidden;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
      padding-bottom: 5px;
      padding-top: 10px;
      
      .content-container {  
        height: fit-content;
        width: 100%;
        letter-spacing: 0.1px;
        .info {
          text-align: right;
          width: 100%;
          font-size: 20px;
          color: grey;
          height: 10px;
        }
        .subscription-title {
          font-size: 20px;
          font-weight: 600;
        }
        .subscription-detail {
          margin-top: 2px;
          display: flex;
          font-size: 16px;
          display: flex;
          align-items: center;
          .point {
            font-size: 20px;
            display: flex;
            align-items: center;
          }
          .price {
            font-size: 34px;
            font-weight: 600;
            margin-top: 4px;
            display: flex;
            align-items: flex-end;
            margin-bottom: 8px;
          }
          .cycle {
            margin-top: 15px;
            display: flex;
            align-items: flex-end;
            height: 100%;
          }
        }
      }
    }
  }
}