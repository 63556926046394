.sidebar-container {
  height: 100vh;
  background: #3F3250 0% 0% no-repeat padding-box;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 90vw;
  z-index: 1;
  transform: translateX(-110%);
  transition: transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 768px) {
    transform: translateX(0%);
    width: 360px;
    align-items: flex-start;
    z-index: 0;
    position: relative;
  }
  .links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 90%;
    @media (min-width: 768px) {
      margin-left: 25px;
      margin-top: 25px;
    }
    .link-1 {
      margin-top: 60px;
      font-size: 28px;
      text-decoration: none;
      font-weight: bold;
      text-align: center;
      padding-bottom: 10px;
      color: rgba(255, 255, 255, 0.6);
      @media (min-width: 768px) {
        margin-top: 30px;
        text-align: left;
      }
    }
    .link {
      margin-top: 40px;
      font-size: 28px;
      text-decoration: none;
      font-weight: bold;
      padding-bottom: 10px;
      text-align: left;
      color: rgba(255, 255, 255, 0.6);
      @media (min-width: 768px) {
        margin-top: 30px;
        text-align: left;
      }
    }
    .link:hover,
    .link:active,
    .link-1:hover,
    .link-1:active,
    .lin:hover,
    .lin:active {
      // padding-bottom: 8px;
      // border-bottom: 2px solid #222222;
    }
  }
  .sidebar-footer {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 25px;
    font-size: 26px;
    color: #9A9A9A;
    .logout {
      width: 100px;
      color: #9A9A9A;
    }
  }
  .logo-container {
    margin: 6%;
    display: none;
  }
}

.sidebar-container.open {
  transform: translateX(0);
}
.header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  margin-top: 5px;
  width: 100%;
  .close-container {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    .close {
      border: solid white;
      border-width: 0 4px 4px 0;
      display: inline-block;
      transform: rotate(135deg);
      height: 18px;
      width: 18px;
    }
  }
}

.header-desktop {
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  .sidebar-logo-img {
    width: 30px;
    height: 45px;
  }
}
